import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Index from './components/views/Main/Index';
import LoginView from './components/views/Login';

export const server = "https://api.voidroleplay.de";

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path="/*" element={< Index />} />
        <Route path='login' element={<LoginView />} />
      </Routes>
    </div>
  );
}

export default App;