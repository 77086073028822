import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { server } from "../../../../App";
import { formatTime, getUser } from "../../../api/Utils";
import { MessageModel, TicketModel } from "../../../api/TicketModel";
import RedButton from "../Items/RedButton";
import GreenButton from "../Items/GreenButton";
import { ConfirmModal } from "../../Utils";

const TicketPage = () => {
    const { id } = useParams();
    const [ticket, setTicket] = useState<TicketModel>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>();
    const [isTicketCloseModalOpen, setTicketCloseModalOpen] = useState(false);

    const openTicketCloseModal = () => {
        setTicketCloseModalOpen(true);
    }

    const closeTicketCloseModal = () => {
        setTicketCloseModalOpen(false);
    }

    useEffect(() => {
        load();
    }, [])

    const load = async () => {
        const response = await fetch(`${server}/tickets/getById`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors',
            body: JSON.stringify({ id: id })
        })

        if (response.redirected) {
            window.location.href = response.url;
            return;
        }

        const responseData = await response.json();

        if (!response.ok) {
            setErrorMessage(responseData.message);
            return;
        }

        setTicket(responseData);
    }

    const sendMessage = async () => {
        const user = getUser(document.cookie);
        if (!user) {
            navigate("/login");
            return;
        };
        const request = await fetch(`${server}/tickets/sendMessage`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors',
            body: JSON.stringify({ message: message, id: id })
        })

        if (request.redirected) {
            window.location.href = request.url;
            return;
        }

        if (!request.ok) {
            const req = await request.json();
            setErrorMessage(req.message);
            return;
        }

        window.location.reload();
    }

    const close = async () => {
        closeTicketCloseModal();
        const user = getUser(document.cookie);
        if (!user) {
            navigate("/login");
            return;
        };
        const request = await fetch(`${server}/tickets/close`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors',
            body: JSON.stringify({ id: id })
        })

        if (request.redirected) {
            window.location.href = request.url;
            return;
        }

        if (!request.ok) {
            const req = await request.json();
            setErrorMessage(req.message);
            return;
        }

        navigate("/tickets");
    }

    return (
        <>
        <ConfirmModal
            onClose={closeTicketCloseModal}
            onConfirm={close}
            open={isTicketCloseModalOpen}
        >
            Bist du sicher, dass du dieses Ticket schließen möchtest?
        </ConfirmModal>
        <div className="bg-neutral-900 min-h-screen p-10">
            {errorMessage && (
                <p className="text-gray-100">{errorMessage}</p>
            )}
            {ticket && (
                <div className="bg-neutral-800 text-white p-6 rounded shadow-lg mb-8">
                    {ticket.closed ? (
                        <div className="relative px-4 py-10 bg-red-700/20 mx-8 md:mx-0 shadow rounded-3xl sm:p-8 mb-2">Ticket wurde am {formatTime(ticket.closedAt.toString())} von {ticket.closerName} geschlossen.</div>
                    ) : (<></>)}
                    <div className="flex justify-between items-start mb-4">
                        <div>
                            <h1 className="text-2xl font-bold mb-2">{ticket.title}</h1>
                            <p className="mb-6">{ticket.creatorName} ({ticket.creatorId})</p>
                            <p className="text-lg">{ticket.message}</p>
                        </div>
                        <RedButton onClick={openTicketCloseModal}>Schließen</RedButton>
                    </div>

                    {ticket.messages.map((message: MessageModel, index: number) => (
                        <Message
                            message={message}
                            key={index} />
                    ))}
                </div>
            )}

            <textarea
                className="w-full h-24 p-2 mb-4 border-2 border-neutral-400 text-white bg-neutral-700/75 rounded shadow-inner"
                value={message}
                onChange={event => setMessage(event.target.value)}
            />
            <GreenButton onClick={sendMessage}>Absenden</GreenButton>
        </div>
        </>
    )

}

interface MessageInterface {
    message: MessageModel
}

const Message = ({ message }: MessageInterface) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedMessage, setEditedMessage] = useState(message.message.text);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

    const openDeleteModal = () => {
        setDeleteModalOpen(true);
    }

    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
    }

    const remove = async () => {
        closeDeleteModal();
        const response = await fetch(`${server}/tickets/removeMessage`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors',
            body: JSON.stringify({id: message.message.id})
        });
    }

    const edit = () => {
        setIsEditing(true);
    }

    const saveEdit = async () => {
        setIsEditing(false);
        const response = await fetch(`${server}/tickets/editMessage`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors',
            body: JSON.stringify({id: message.message.id, message: editedMessage})
        });

        if (!response.ok) {
            setEditedMessage(message.message.text);
        }
    }

    const cancelEdit = () => {
        setEditedMessage(message.message.text);
        setIsEditing(false);
    }

    return (
        <>
            <ConfirmModal
                onClose={closeDeleteModal}
                onConfirm={remove}
                open={isDeleteModalOpen}
            >
                Bist du sicher, dass du die Nachricht löschen möchtest?
            </ConfirmModal>

            <div className="flex flex-col md:flex-row mb-4 p-4 border-2 border-neutral-700 rounded shadow-lg">
                <div className="flex-shrink-0">
                    <p className="font-bold">{message.sender.name}</p>
                    <p className="text-white px-2 py-1 rounded mb-2 text-center" style={{ background: message.sender.rank.color }}>{message.sender.rank.name}</p>
                </div>
                {isEditing ? (
                    <input
                        type="text"
                        className="ml-0 border-transparent bg-transparent md:ml-4 mt-2 md:mt-0 p-2 border rounded w-full"
                        value={editedMessage}
                        onChange={(e) => setEditedMessage(e.target.value)}
                    />
                ) : (
                    <p className="ml-0 md:ml-4 mt-2 md:mt-0">{message.message.text}</p>
                )}
                <p className="ml-0 md:ml-auto mt-2 md:mt-0 text-sm text-right">{formatTime(message.message.send.toString())}</p>
                <div className="ml-0 md:ml-4 mt-2 md:mt-0 flex justify-end">
                    {isEditing ? (
                        <>
                            <button onClick={saveEdit} className="text-green-500 hover:text-green-700 mr-2">Save</button>
                            <button onClick={cancelEdit} className="text-gray-500 hover:text-gray-700">Cancel</button>
                        </>
                    ) : (
                        <>
                            <button onClick={edit} className="text-blue-500 hover:text-blue-700 mr-2">Edit</button>
                            <button onClick={openDeleteModal} className="text-red-500 hover:text-red-700">Delete</button>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default TicketPage;