import { useNavigate } from "react-router-dom";
import { server } from "../../App";
import { useEffect, useState } from "react";
import { CenteredLoader, ConfirmModal } from "./Utils";

const LoginView = () => {
    const [form, setForm] = useState({ user: '', password: '' });
    const [isLoading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [statistics, setStatistics] = useState<any>();
    const [isConfirmModalOpened, setConfirmModalOpened] = useState<boolean>(false);

    useEffect(() => {
        const load = async () => {
            const response = await fetch(server + '/stats', {
                method: 'GET',
                mode: 'cors',
              });
          
              if (!response.ok) {
                return setStatistics(undefined);
              }
          
              const statData = await response.json();
              setStatistics(statData);
        }

        load();
    }, [])

    const navigate = useNavigate();

    let handleConnect = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const response = await fetch(`${server}/connectCharacter`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        });

        console.log(response);

        if (response.ok) {
            setConfirmModalOpened(true);
        } else {
            const data = await response.json();
            setErrorMessage(data.message);
        }
        setLoading(false);
    }

    let handleLogin = (e: any) => {
        e.preventDefault();
        login();
    };

    const login = async () => {
        setConfirmModalOpened(false);
        setLoading(true);
        const response = await fetch(`${server}/account/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            body: JSON.stringify(form),
        });
        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(data.message);
            setLoading(false);
            return;
        }

        setForm({ user: '', password: '' });
        document.cookie = `user=${JSON.stringify(data)}`;
        navigate("/");
        setLoading(false);
    }

    const handleChange = (e: any) => {
        setForm({...form, [e.target.name]: e.target.value});
    }

    const closeConfirmModal = () => {
        setConfirmModalOpened(false);
    }

    return (
        <>
        <div className="bg-neutral-900 min-w-screen min-h-screen">
            <ConfirmModal 
                open={isConfirmModalOpened}
                onClose={closeConfirmModal}
                onConfirm={login}
            >
                Wir habe deinem Charakter eine Anfrage gesendet. Bitte bestätige diese.
            </ConfirmModal>
            <div className="relative py-1 sm:max-w-xl sm:mx-auto">
            {errorMessage && (
            <div
                    className={`relative px-4 py-10 bg-red-700/20 mx-8 md:mx-0 shadow rounded-3xl sm:p-8 mt-10`}
                >
                        <div className="text-gray-100">
                            {errorMessage}
                        </div>
                    
                </div>
            )}
                <div
                    className={`relative px-4 py-10 ${statistics === undefined ? 'bg-red-700/20' : 'bg-green-500/80'} mx-8 md:mx-0 shadow rounded-3xl sm:p-8 mt-10`}
                >
                    {statistics === undefined ? (
                        <div className="text-gray-100">
                            Server ist offline, Login gesperrt.
                        </div>
                    ) : (
                        <>
                        <div className="text-gray-100">
                            Es {statistics.players == 1 ? 'ist' : 'sind'} {statistics.players} Spieler online.
                        </div>
                        </>
                    )}
                </div>
            </div>
            <div className="relative py-1 sm:max-w-xl sm:mx-auto">
                <div
                    className="relative px-4 py-10 bg-neutral-400/10 mx-8 md:mx-0 shadow rounded-3xl sm:p-10 mt-10"
                >
                    <div className="max-w-md mx-auto">
                        <form>
                            <div className="mt-5 grid sm:mx-10 gap-5">
                                <div>
                                    <label
                                        className="font-semibold text-sm text-gray-100 pb-1 block"
                                        htmlFor="username"
                                    >Ingame Name</label
                                    >
                                    <input
                                        required
                                        className="text-neutral-400 border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full bg-neutral-700/75 border-neutral-400"
                                        type="text"
                                        placeholder="Max_Mustermann"
                                        id="username"
                                        name="user"
                                        onChange={handleChange}
                                        value={form.user}
                                    />
                                </div>
                                <div>
                                    <label
                                        className="font-semibold text-sm text-gray-100 pb-1 block"
                                        htmlFor="password"
                                    >Password</label
                                    >
                                    <input
                                        required
                                        className="text-neutral-400 border rounded-lg px-3 py-2 mt-1 mb-5 text-sm w-full bg-neutral-700/75 border-neutral-400"
                                        type="password"
                                        placeholder="●●●●●●●●"
                                        id="password"
                                        name="password"
                                        onChange={handleChange}
                                        value={form.password}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <div>
                                    <button
                                        className="flex items-center justify-center py-2 px-20 bg-neutral-600/40 hover:bg-neutral-600/60 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                        onClick={handleConnect}
                                    >
                                        <img width={25} height={25} src="https://czesiek2000.gitlab.io/ragemp-tutorial/img/logo.png" />
                                        <span className="ml-2">Ingame-Account verbinden</span>
                                    </button>
                                </div>
                            </div>
                            <div className="mt-5">
                                <button
                                    className="py-2 px-4 bg-red-600/60 hover:bg-red-600/90 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg"
                                    onClick={handleLogin}
                                >
                                    {isLoading ? (
                                        <CenteredLoader />
                                    ) : (
                                        <span>Login</span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </div>
        </>
    )
}

export default LoginView;