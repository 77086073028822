import ButtonPropInterface from "../../../api/ButtonPropInterface";


const SmallGreenButton: React.FC<ButtonPropInterface> = ({ children, onClick }) => {
    return (
        <>
            <button onClick={onClick} className="m-5 py-1 px-6 text-white text-l font-bold rounded-lg shadow-lg transform transition-transform duration-150 hover:scale-95" style={{ background: "linear-gradient(90deg, rgb(67, 133, 58), rgb(53, 177, 37))" }}>
                {children}
            </button>
        </>
    )
}

export default SmallGreenButton;