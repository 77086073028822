import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { server } from "../../../../App";
import SmallGreenButton from "../Items/SmallGreenButton";
import { getUser } from "../../../api/Utils";

const CreateTicketPage = () => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();
    const [form, setForm] = useState({ title: '', message: '', category: '' });
    const [categories, setCategories] = useState<string[]>(['Entwicklung', 'Support', 'Bug']);

    useEffect(() => {
        const load = async () => {
            const user = getUser(document.cookie);
            if (!user) {
                navigate("/login");
                return;
            };
            try {
                const response = await fetch(`${server}/tickets/GetCategories`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${getUser(document.cookie)}`
                    },
                    mode: 'cors',
                });

                if (response.redirected) {
                    window.location.href = response.url;
                    return;
                }

                const responseData = await response.json();

                console.log(responseData);

                if (!response.ok) {
                    setErrorMessage(responseData.message);
                    return;
                }

                setCategories(responseData);
            } catch (error) {
                setErrorMessage("Failed to load categories.");
            }
        }

        load();
    }, [navigate]);

    const create = async () => {
        const user = getUser(document.cookie);
        if (!user) {
            navigate("/login");
            return;
        };
        try {
            const request = await fetch(`${server}/tickets/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getUser(document.cookie)}`
                },
                mode: 'cors',
                body: JSON.stringify({ title: form.title, message: form.message, category: form.category })
            });

            if (request.redirected) {
                window.location.href = request.url;
                return;
            }
            const req = await request.json();
            if (!request.ok) {
                setErrorMessage(req.message);
                return;
            }

            navigate(`/tickets/${req.id}`);
        } catch (error) {
            setErrorMessage("Failed to create ticket.");
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    return (
        <>
            <div className="bg-neutral-900 min-h-screen p-10">
                {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}
                <div className="flex flex-col bg-neutral-800 text-white p-8 rounded shadow max-w-md mx-auto">
                    <label className="font-bold mb-2" htmlFor="title">Ticket</label>
                    <input className="border-2 border-neutral-400 rounded p-2 mb-4 bg-neutral-700/75" onChange={handleInputChange} value={form.title} name="title" id="title" />

                    <label className="font-bold mb-2" htmlFor="message">Nachricht</label>
                    <textarea className="border-2 border-neutral-400 rounded p-2 mb-4 h-24 bg-neutral-700/75" onChange={handleInputChange} value={form.message} name="message" id="message" />

                    <label className="font-bold mb-2" htmlFor="category">Kategorie</label>
                    <select className="border-2 border-neutral-400 rounded p-2 mb-4 bg-neutral-700/75" onChange={handleInputChange} value={form.category} name="category" id="category">
                    {categories.map((category: any, index: number) => (
                            <option key={index} value={category.name}>{category.name}</option>
                        ))}
                    </select>

                    <SmallGreenButton onClick={create}>Erstellen</SmallGreenButton>
                </div>
            </div>
        </>
    )
}

export default CreateTicketPage;
