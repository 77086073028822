import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { server } from "../../../../App";
import { formatTime, getUser } from "../../../api/Utils";
import GreenButton from "../Items/GreenButton";
import { TicketModel } from "../../../api/TicketModel";

const TicketsPage = () => {
    const [tickets, setTickets] = useState<any>([]);
    const [errorMessage, setErrorMessage] = useState<string>();
    const navigate = useNavigate();
    const [filter, _setFilter] = useState<string>('Open');
    const [currentPage, setCurrentPage] = useState<number>(1);

    const setFilter = (filter: string) => {
        _setFilter(filter);
        switch (filter) {
            case "Open":
                setOpen();
                break;
            case "All":
                setAll();
                break;
        }
    }

    const setAll = async () => {
        const response = await fetch(`${server}/tickets/myTicketsAll`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors'
        })

        if (response.redirected) {
            window.location.href = response.url;
            return;
        }

        const responseData = await response.json();

        if (!response.ok) {
            setErrorMessage(responseData.message);
            return;
        }

        setTickets(responseData);
    }

    const setOpen = async () => {
        const response = await fetch(`${server}/tickets/myTickets`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${getUser(document.cookie)}`
            },
            mode: 'cors'
        })

        if (response.redirected) {
            window.location.href = response.url;
            return;
        }

        const responseData = await response.json();

        if (!response.ok) {
            setErrorMessage(responseData.message);
            return;
        }

        setTickets(responseData);
    }

    useEffect(() => {
        const load = async () => {
            const response = await fetch(`${server}/tickets/myTickets`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${getUser(document.cookie)}`
                },
                mode: 'cors'
            })

            if (response.redirected) {
                window.location.href = response.url;
                return;
            }

            const responseData = await response.json();

            if (!response.ok) {
                setErrorMessage(responseData.message);
                return;
            }

            setTickets(responseData);
        }

        load();
    }, [])

    const createNewTicket = () => {
        navigate("/create-ticket");
    }

    const ticketsPerPage = 5; // Number of tickets per page
    const indexOfLastTicket = currentPage * ticketsPerPage;
    const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
    const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    return (
        <>
        <div className="bg-neutral-900 min-h-screen p-10">
            <div className="flex justify-between items-center mb-4">
                <div className="flex">
                    <button
                        className={`px-4 py-2 mr-2 ${filter === 'Open' ? 'bg-blue-500' : 'bg-neutral-700'} text-white rounded`}
                        onClick={() => setFilter('Open')}
                    >
                        Offene
                    </button>
                    <button
                        className={`px-4 py-2 ${filter === 'All' ? 'bg-blue-500' : 'bg-neutral-700'} text-white rounded`}
                        onClick={() => setFilter('All')}
                    >
                        Alle
                    </button>
                </div>
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded"
                    onClick={createNewTicket}
                >
                    Ticket erstellen
                </button>
            </div>
            {errorMessage && <p className="text-white mb-4">{errorMessage}</p>}
            {tickets && tickets.map((ticket: TicketModel, index: number) => (
                <div key={index} className="bg-neutral-800 text-white p-4 mb-4 rounded shadow">
                    <Link to={`/tickets/${ticket.id}`}>
                        <div className="flex justify-between items-center">
                            <h3 className="text-xl font-bold">{ticket.title}</h3>
                            <p className="px-2 py-1 rounded" style={{ background: ticket.category.typeColor.background, color: ticket.category.typeColor.font }}>{ticket.category.type}</p>
                        </div>
                        <p>Geöffnet {formatTime(ticket.created.toString())}</p>
                    </Link>
                </div>
            ))}
             <div className="flex justify-end mt-4">
                {tickets && (
                    <>
                                    <nav className="block">
                    <ul className="flex pl-0 list-none rounded overflow-hidden">
                        {Array.from({ length: Math.ceil(tickets.length / ticketsPerPage) }, (_, index) => index + 1).map((pageNumber) => (
                            <li key={pageNumber}>
                                <button
                                    className={`px-3 py-2 mx-1 rounded ${pageNumber === currentPage ? 'bg-blue-500 text-white' : 'bg-neutral-700 text-white'}`}
                                    onClick={() => paginate(pageNumber)}
                                >
                                    {pageNumber}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
                    </>
                )}
            </div>
        </div>
        </>
    );
}

export default TicketsPage;