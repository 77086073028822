import ButtonPropInterface from "../../../api/ButtonPropInterface";


const RedButton: React.FC<ButtonPropInterface> = ({ children, onClick }) => {
    return (
        <>
            <button onClick={onClick} className="m-5 py-3 px-6 text-white text-xl font-bold rounded-lg shadow-lg transform transition-transform duration-150 hover:scale-95" style={{ background: "linear-gradient(90deg, #bd333c, #FE3543)" }}>
                {children}
            </button>
        </>
    )
}

export default RedButton;